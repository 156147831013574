<template>
  <div id="main-wrapper" class="show">
    <div class="header">
      <div class="header-content">
        <nav class="navbar navbar-expand">
          <div class="collapse navbar-collapse justify-content-between">
            <div class="header-left">
              <div class="dashboard_bar">
                {{ pageName }}
              </div>
            </div>
            <ul class="navbar-nav header-right">
              <!-- <li class="nav-item dropdown notification_dropdown">
                        <el-button @click="changeAccType" class="bg-primary text-white p-3 border-radius">
                          <span>{{$store.state.User.user.IsCarrier ? "Nakiliyeci" : "Tedarikçi"}}</span>
                        </el-button>
                      </li> -->
              <li class="nav-item dropdown notification_dropdown">
                <!-- <el-button
                  @click="reportErrorDialog = true"
                  class="bg-danger text-white p-3 border-radius"
                >
                  <span>Hata Bildir!</span>
                </el-button> -->
                <el-button @click="howToUseDialog = true" class="bg-blue text-white p-3 border-radius d-none d-md-block">
                  <span>Nasıl Kullanılır?</span>
                </el-button>
                <el-button v-if="!user.IsCarrier" @click="handleOpenEditAd" class="bg-primary text-white p-3 border-radius">
                  <span>İlan Oluştur</span>
                </el-button>

                <div class="nakubox bg-primary border-radius d-flex justify-content-between align-items-center p-1">
                  <img src="../assets/images/naku.svg" alt="" style="width: 32px" />
                  <div class="total-naku">
                    <span class="fs-15 text-white">NAKU Hesabın:</span>
                    <span class="fs-15 text-white"> {{ naku }} <strong>NA</strong>KU</span>
                  </div>
                  <button
                    @click="
                      () => {
                        if ($store.state.User.token) {
                          $router.push({ path: '/magaza' });
                        } else {
                          openLoginDialog();
                        }
                      }
                    "
                    class="bg-dark"
                  >
                    <img src="../assets/icons/nkicon/shop.svg" style="max-width: 25px; padding: 4px" alt="" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>

    <el-dialog :close-on-click-modal="false" :title="advertTitle" :visible.sync="adsDialog" :modal-append-to-body="false" :before-close="clearAdFormData">
      <div>
        <div class="ads-info row">
          <h6>Araç Tipi</h6>
          <a class="col-md-6" href="javascript:;" @click="selectVehicleType(2)">
            <div class="bg-light border-radius p-3 border d-flex justify-content-center align-items-center" id="lorry" style="min-height: 100px" :class="adForm.VehicleType === 2 ? 'select-active' : null">
              <img src=".././assets/images/kamyonet-kasali.svg" alt="" />
            </div>
            <p>Kamyonet</p>
          </a>
          <a class="col-md-6" href="javascript:;" @click="selectVehicleType(1)">
            <div class="bg-light border-radius p-3 border d-flex justify-content-center align-items-center" id="lorry" style="min-height: 100px" :class="adForm.VehicleType === 1 ? 'select-active' : null">
              <img src=".././assets/images/kamyon.svg" alt="" />
            </div>
            <p>Kamyon</p>
          </a>

          <a class="col-md-6" href="javascript:;" @click="selectVehicleType(0)">
            <div class="bg-light border-radius p-3 border d-flex justify-content-center align-items-center" id="truck" style="min-height: 100px" :class="adForm.VehicleType === 0 ? 'select-active' : null">
              <img src=".././assets/images/tir.svg" alt="" />
            </div>
            <p>Tır</p>
          </a>

          <a class="col-md-12 mb-3" href="javascript:;" @click="selectVehicleType(null)">
            <div class="bg-light border border-radius p-3" id="dontMatter" :class="adForm.VehicleType === null ? 'select-active' : null">
              <span>Farketmez</span>
            </div>
          </a>
          <!-- <div class="col-md-12 mb-3">
            <h6>Ödeme Yöntemi</h6>
            <div
              class="paymentMethod d-flex justify-content-around"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'PaymentType'),
              }"
            >
              <span
                :class="adForm.PaymentType === 0 ? 'active' : null"
                @click="
                  () => {
                    paymentGocDialog = true;
                    adForm.PaymentType = 0;
                  }
                "
              >
                GÖÇ
              </span> 
              <span
                :class="adForm.PaymentType === 0 ? 'active' : null"
                @click="
                  () => {
                    paymentGocSoonDialog = true;
                    adForm.PaymentType = 0;
                  }
                "
              >
                GÖÇ
              </span>
              <span :class="adForm.PaymentType === 3 ? 'active' : null" @click="adForm.PaymentType = 3">
                Havale
              </span>
              <span :class="adForm.PaymentType === 2 ? 'active' : null" @click="adForm.PaymentType = 2">
                Nakit
              </span>
            </div>
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'PaymentType')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "PaymentType")[0].Value }}</span>
          </div> -->
          <!-- <div class="col-md-6 mb-3">
            <h6>Dorse Tipi</h6>
            <a @click="dorseDialog = true" href="javascript:;">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  border-radius border
                  bg-light
                  p-3
                "
              >
                <span v-if="adForm.TrailerIds.length === 0">Lütfen Bir Dorse Tipi Seçiniz </span>
                <span v-else style="word-break: break-word">{{ getTrailerNames() }} </span>
                <img src=".././assets/images/add.svg" alt="" />
              </div>
            </a>
          </div> -->

          <div class="col-md-6 mb-3">
            <h6>Araç Sayısı</h6>
            <el-input
              type="number"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'VehicleCount'),
              }"
              placeholder="(Örn. 1)"
              v-model="adForm.VehicleCount"
              min="1"
            />
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'VehicleCount')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "VehicleCount")[0].Value }}</span>
          </div>

          <div class="col-md-6 mb-3">
            <h6>Ağırlık(KG)/Araç</h6>
            <el-input
              type="text"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'ProductWeight'),
              }"
              placeholder="Lütfen Bir Ağırlık/Araç Tonajı Giriniz"
              :value="adForm.ProductWeight"
              @input="updateProductWeight"
              min="0"
              maxlength="6"
            />
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'ProductWeight')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "ProductWeight")[0].Value }}</span>
          </div>

          <!-- <div class="col-md-6 mb-3">
            <h6>Birim Fiyatı</h6>
            <el-input
              type="text"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some(
                  (x) => x.Key == 'UnitPrice'
                ),
              }"
              placeholder="Lütfen birim fiyatı giriniz"
              :value="adForm.UnitPrice"
              @input="updateUnitPrice"
              min="0"
              maxlength="8"
            />
            <span
              class="validation-error d-block"
              v-if="
                advertResponse.ValidationErrors.some(
                  (x) => x.Key == 'UnitPrice'
                )
              "
              >{{
                advertResponse.ValidationErrors.filter(
                  (x) => x.Key == "UnitPrice"
                )[0].Value
              }}</span
            >
          </div> -->

          <div class="col-md-12 mb-3">
            <h6>Ürün Cinsi</h6>
            <el-input
              type="text"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'ProductType'),
              }"
              placeholder="Lütfen bir ürün cinsi giriniz"
              v-model="adForm.ProductType"
            />
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'ProductType')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "ProductType")[0].Value }}</span>
          </div>
          <!-- <div class="col-md-6 mb-3">
            <h6>Depo</h6>
            <a @click="depotSelectDialog = true" href="javascript:;">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  border-radius border
                  bg-light
                  p-3
                "
              >
                <span>{{ warehouseTitle ? warehouseTitle : "Lütfen Bir Depo Seçiniz" }} </span>
                <img src=".././assets/icons/nkicon/down-arrow.svg" alt="" />
              </div>
              <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "WarehouseId")[0].Value }}</span>
            </a>
          </div> -->
          <div class="col-md-12 mb-3">
            <h6>Yükleme Tarihi</h6>
            <div class="row">
              <div class="col-6">
                <el-button
                  class="bg-light border-radius me-2 py-2 w-100 h-100"
                  :class="dateIsNow ? 'select-active' : null"
                  @click="
                    adForm.AdvertDate = new Date();
                    dateIsNow = true;
                  "
                  >Hemen</el-button
                >
              </div>
              <div class="col-6">
                <el-date-picker
                  class="bg-light p-2 w-100"
                  v-model="adForm.AdvertDate"
                  @change="dateIsNow = false"
                  type="datetime"
                  :class="{
                    'select-active': dateIsNow == false,
                    'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'AdvertDate'),
                  }"
                  placeholder="İleri Tarihli"
                >
                </el-date-picker>
              </div>
            </div>
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'AdvertDate')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "AdvertDate")[0].Value }}</span>
          </div>
          <div class="col-md-12 mb-3">
            <h6>Yükleme Noktası</h6>
            <div class="row">
              <div class="col-6">
                <!-- <ul>
                  <li v-for="city in cityState" :key="city.ID">{{ city.Title }}</li>
                </ul> -->
                <el-select
                  type="text"
                  class="form-control"
                  :class="{
                    'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseCityId'),
                  }"
                  placeholder="İl"
                  v-model="adForm.WarehouseCityId"
                  filterable
                  @change="getDistricts('warehouse')"
                >
                  <el-option v-for="(city, index) in cityState" :key="index" :label="city.Title" :value="city.ID"> </el-option>
                </el-select>
                <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseCityId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "WarehouseCityId")[0].Value }}</span>
              </div>
              <div class="col-6">
                <el-select
                  type="text"
                  class="form-control"
                  :class="{
                    'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseDistrictId'),
                  }"
                  placeholder="İlçe"
                  v-model="adForm.WarehouseDistrictId"
                  filterable
                >
                  <el-option v-for="(district, index) in warehouseDistrictIds" :key="index" :label="district.Title" :value="district.ID"> </el-option>
                </el-select>
                <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseDistrictId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "WarehouseDistrictId")[0].Value }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12 mb-3">
            <h6>Teslimat Yapılacak Kişinin Adı</h6>
            <el-input
              type="text"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'ContactName'),
              }"
              placeholder="Örn: Ahmet"
              v-model="adForm.ContactName"
            />
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'ContactName')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "ContactName")[0].Value }}</span>
          </div>
          <div class="col-md-12 mb-3">
            <h6>İletişim Kurulacak Telefon Numarası</h6>
            <el-input
              type="text"
              class="form-control"
              :class="{
                'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'ContactNumber'),
              }"
              v-mask="['+90 (###) ### ## ##']"
              placeholder="+90 (xxx) xxx xx xx"
              v-model="adForm.ContactNumber"
              @keydown.native="handleKeydown(6, $event)"
              @focus="moveCursorToIndex(6, $event)"
              @click.native="moveCursorToIndex(6, $event)"
            />
            <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'ContactNumber')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "ContactNumber")[0].Value }}</span>
          </div>
          <div class="col-md-12 mb-3">
            <h6>Teslimat Noktası</h6>
            <div class="row">
              <div class="col-6">
                <el-select
                  type="text"
                  class="form-control"
                  :class="{
                    'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'DeliveryCompanyCityId'),
                  }"
                  placeholder="İl"
                  v-model="adForm.DeliveryCompanyCityId"
                  filterable
                  @change="getDistricts('delivery')"
                >
                  <el-option v-for="(city, index) in cityState" :key="index" :label="city.Title" :value="city.ID"> </el-option>
                </el-select>
                <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'DeliveryCompanyCityId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "DeliveryCompanyCityId")[0].Value }}</span>
              </div>
              <div class="col-6">
                <el-select
                  type="text"
                  class="form-control"
                  :class="{
                    'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'DeliveryCompanyDistrictId'),
                  }"
                  placeholder="İlçe"
                  v-model="adForm.DeliveryCompanyDistrictId"
                  filterable
                >
                  <el-option v-for="(district, index) in deliveryDistricts" :key="index" :label="district.Title" :value="district.ID"> </el-option>
                </el-select>
                <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'DeliveryCompanyDistrictId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "DeliveryCompanyDistrictId")[0].Value }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 mb-3">
            <h6>Teslimat Firması</h6>
            <a @click="locationSelectDialog = true" href="javascript:;">
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  border-radius border
                  bg-light
                  p-3
                "
              >
                <span>{{ deliveryCompanyTitle ? deliveryCompanyTitle : "Lütfen Bir Teslimat Firması Seçiniz" }} </span>
                <img src=".././assets/icons/nkicon/down-arrow.svg" alt="" />
              </div>
              <span class="validation-error d-block" v-if="advertResponse.ValidationErrors.some((x) => x.Key == 'DeliveryCompanyId')">{{ advertResponse.ValidationErrors.filter((x) => x.Key == "DeliveryCompanyId")[0].Value }}</span>
            </a>
          </div> -->

          <div class="col-md-12 mb-3">
            <h6>Detaylı Bilgi</h6>
            <el-input class="form-control" type="textarea" :rows="4" placeholder="Detaylı bilgi veriniz..." :maxlength="150" v-model="adForm.Details"> </el-input>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <span slot="footer" class="d-flex align-items-center">
          <!-- <span style="text-decoration: underline; cursor: pointer" @click="handleWhatIsGocDialog" title="Sevkiyat ödemelerinizi nakliyekullan.com güvencesi ile kredi kartınızla ödemeyi sağlayan Güvenli Ödeme Çözümleri’dir. Sevkiyatınız tamamlanana kadar havuzda tutulan paranız sevkiyat tamamlandıktan sonra nakliyeciye ödenir."> GÖÇ ödeme yöntemi nedir?</span> -->
        </span>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button :loading="loading" @click="addAdvert"
            ><span v-if="method === 'post'">{{ systemSettings.NewAdvertNakuAmount }}X <img src=".././assets/images/naku.svg" alt="" style="width: 40px"/></span> {{ addAdvertText }}</el-button
          > -->
          <el-button class="mb-2" :loading="loading" @click="addAdvert"><span v-if="method === 'post'"></span> {{ addAdvertText }}</el-button>
        </span>
      </div>
    </el-dialog>
    <el-dialog :title="'GÖÇ ödeme yöntemi nedir?'" :visible.sync="whatIsGocDialog" :modal-append-to-body="false" :before-close="handleWhatIsGocDialog">
      <div class="d-flex flex-column">
        <div>Sevkiyat ödemelerinizi nakliyekullan.com güvencesi ile kredi kartınızla ödemeyi sağlayan Güvenli Ödeme Çözümleri’dir. Sevkiyatınız tamamlanana kadar havuzda tutulan paranız sevkiyat tamamlandıktan sonra nakliyeciye ödenir.</div>
        <el-button class="bg-blue mt-2 mb-2" @click="handleWhatIsGocDialog"> Tamam</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog
      :title="'Göç Ödemesi'"
      :visible.sync="paymentGocDialog"
      :modal-append-to-body="false"
      :before-close="handlePaymentGocDialog"
    >
      <div class="d-flex flex-column">
        <div>
          Eğer ödeme yöntemi GÖÇ olarak belirtilir ise, göç ödemesi gerçekleşene
          kadar sevkiyatınız ile ilgili işlem yapamayacaksınız.
        </div>
        <el-button class="bg-blue mt-4 mb-2" @click="handlePaymentGocDialog">
          Tamam</el-button
        >
      </div>
    </el-dialog> -->
    <!-- <el-dialog :title="'Göç Ödemesi Çok yakında'" :visible.sync="paymentGocSoonDialog" :modal-append-to-body="false" :before-close="handlePaymentGocSoonDialog">
      <div class="d-flex flex-column">
        <div>
          Göç ödemesi çok yakında hizmetinizde...
        </div>
        <el-button class="bg-blue mt-4 mb-2" @click="handlePaymentGocSoonDialog"> Tamam</el-button>
      </div>
    </el-dialog> -->
    <!-- <el-dialog :close-on-click-modal="false" title="Dorse Tipi Seçimi" :visible.sync="dorseDialog">
      <div class="row" v-if="adForm.VehicleType == 0">
        <div class="col-md-4 mb-5 dorse-type" v-for="(item, index) in $store.state.trailers.truckTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img
            class="w-100"
            :class="{
              'select-active': adForm.TrailerIds.some((x) => x == item.ID),
            }"
            :src="item.Image"
            alt=""
          />
          <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <div class="row" v-else-if="adForm.VehicleType == 1">
        <div class="col-md-4 mb-5 dorse-type" v-for="(item, index) in $store.state.trailers.lorryTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img
            class="w-100"
            :class="{
              'select-active': adForm.TrailerIds.some((x) => x == item.ID),
            }"
            :src="item.Image"
            alt=""
          />
          <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <div class="row" v-else-if="adForm.VehicleType == 2">
        <div class="col-md-4 mb-5 dorse-type" v-for="(item, index) in $store.state.trailers.pickupTrailers" :key="index" @click="selectTrailerType(item.ID)">
          <img
            class="w-100"
            :class="{
              'select-active': adForm.TrailerIds.some((x) => x == item.ID),
            }"
            :src="item.Image"
            alt=""
          />
          <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <div class="row" v-else-if="adForm.VehicleType === null">
        <div class="col-md-4 mb-5 dorse-type" v-for="(item, index) in getAllTrailers()" :key="index" @click="selectTrailerType(item.ID)">
          <img
            class="w-100"
            :class="{
              'select-active': adForm.TrailerIds.some((x) => x == item.ID),
            }"
            :src="item.Image"
            alt=""
          />
          <br />
          <span class="text-white mt-2">{{ item.Title }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog> -->

    <el-dialog :close-on-click-modal="false" title="Depo Seçimi" :visible.sync="depotSelectDialog">
      <div class="row px-3">
        <a
          class="col-md-12 p-3 mb-3 bg-light border-radius border"
          :class="{
            'input-validation-error': advertResponse.ValidationErrors.some((x) => x.Key == 'WarehouseId'),
          }"
          v-for="(item, index) in warehouses"
          :key="index"
          href="javascript:;"
          @click="selectWarehouse(item)"
          ><span class="text-white mt-2">{{ item.Title }}</span></a
        >
        <a
          href="javascript:;"
          @click="
            () => {
              depotSelectDialog = false;
              adsDialog = false;
              $router.push({
                path: '/profil',
                query: { tab: 1 },
              });
            }
          "
          class="col-md-12 d-flex justify-content-between align-items-center add-depot bg-primary border-radius p-3"
        >
          <div>
            <span class="fs-16 text-white">Yeni Bir Depo Ekle</span>
          </div>
          <div>
            <img src=".././assets/images/add.svg" alt="" />
          </div>
        </a>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <!-- <el-dialog
      :close-on-click-modal="false"
      title="Hata Bildir"
      :visible.sync="reportErrorDialog"
    >
      <p class="text-white">
        Böyle bir durum yaşadığınız için çok üzgünüz. Bizimle,
        destek@nakliyekullan.com üzerinden iletişime geçebilirsiniz.
      </p>
      <span class="text-blue"
        >Bildiriminiz tarafımızca incelendikten sonra size geri dönüş
        sağlayacağız. Eğer butona yanlışlıkla tıkladıysanız bu sayfayı
        kapatınız.</span
      >
      <div class="mt-3">
        <label for="">Açıklama</label>
        <textarea
          class="form-control"
          :class="{
            'input-validation-error': reportErrorResponse.ValidationErrors.some(
              (x) => x.Key == 'Reason'
            ),
          }"
          type="textarea"
          :rows="4"
          minlength="10"
          placeholder="Lütfen Açıklamanızı Giriniz"
          v-model="reportForm.Error"
        >
        </textarea>
        <span
          class="validation-error"
          v-if="
            reportErrorResponse.ValidationErrors.some((x) => x.Key == 'Reason')
          "
          >{{
            reportErrorResponse.ValidationErrors.filter(
              (x) => x.Key == "Reason"
            )[0].Value
          }}</span
        >
        <div class="d-flex justify-content-end">
          <el-button
            :loading="loading"
            class="bg-primary py-3 mt-3 w-25"
            @click="reportError"
            >Gönder</el-button
          >
        </div>
      </div>
    </el-dialog> -->
    <el-dialog :close-on-click-modal="false" title="Nasıl Kullanılır?" :visible.sync="howToUseDialog">
      <div v-if="user.IsCarrier" class="d-flex justify-content-center pb-3" v-html="systemSettings.CarrierVideo ? systemSettings.CarrierVideo : ''"></div>
      <div v-else class="d-flex justify-content-center pb-3" v-html="systemSettings.SupplierVideo ? systemSettings.SupplierVideo : ''"></div>
    </el-dialog>

    <el-dialog :close-on-click-modal="false" title="Teslimat Firması Seçimi" :visible.sync="locationSelectDialog">
      <div class="row px-3">
        <a class="col-md-12 p-3 mb-3 bg-light border-radius border" v-for="(item, index) in deliveryCompanies" :key="index" href="javascript:;" @click="selectDeliveryCompany(item)"
          ><span class="text-white mt-2">{{ item.Title }}</span></a
        >
        <a
          href="javascript:;"
          @click="
            () => {
              locationSelectDialog = false;
              adsDialog = false;
              $router.push({
                path: '/profil',
                query: { tab: 2 },
              });
            }
          "
          class="col-md-12 d-flex justify-content-between align-items-center add-depot bg-primary border-radius p-3"
        >
          <div>
            <span class="fs-16 text-white">Yeni Bir Teslimat Firması Ekle</span>
          </div>
          <div>
            <img src=".././assets/images/add.svg" alt="" />
          </div>
        </a>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>

    <el-dialog class="successDialog" title="İlan Oluşturuldu" :visible.sync="successDialog">
      <img class="w-100 mb-3" src=".././assets/images/ilanolusturuldu.svg" alt="" />
      <span class="text-white">Harika! İlanınız Oluşturuldu. Nakliyeciler en kısa sürede teklif verecekler. Hazır olun!</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="w-100" @click="turnHome">Anasayfaya Dön</el-button>
      </span>
    </el-dialog>

    <!-- Sidebar Start -->
    <Sidebar />

    <!-- Page Start -->
    <div class="content-body">
      <!-- row -->
      <div class="container-fluid">
        <transition name="fade">
          <router-view />
        </transition>
      </div>
    </div>
    <section v-if="showCookiePolicy" id="cookie-section" class="cookie-section">
      <div class="row">
        <div class="col-8">
          <p class="pl-2" style="padding-left: 20px !important">
            Size daha iyi bir deneyim sunmak için çerezleri kullanıyoruz. Devam ederek sitemizdeki
            <a class="cookie-link" href="javascript:;" @click="cookieDialog = true"> çerez politikası</a>nı kabul ediyorsunuz.
          </p>
        </div>
        <div class="col-4">
          <el-button class="cookie-button" @click="handleAcceptPolicy"> Kabul et ve kapat</el-button>
        </div>
      </div>
    </section>
    <el-dialog :title="cookieInfo.Title" :visible.sync="cookieDialog">
      <div class="d-flex flex-column">
        <div class="policy-dialog" v-html="cookieInfo.Content"></div>
        <el-button class="cookie-button m-0" @click="handleAcceptPolicy"> Kabul et ve kapat</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="'Sözleşmeler'" :close-on-press-escape="false" :show-close="false" :close-on-click-modal="false" :visible.sync="policiesDialog">
      <div class="d-flex flex-column">
        <h4>{{ kvkkInfo.Title }}</h4>
        <div class="policy-dialog" v-html="kvkkInfo.Content"></div>
      </div>
      <div class="d-flex flex-column policy-dialog">
        <h4>{{ userAgreementInfo.Title }}</h4>
        <div class="policy-dialog" v-html="userAgreementInfo.Content"></div>
      </div>
      <div class="d-flex flex-column policy-dialog">
        <h4>{{ confidentialityAgreementInfo.Title }}</h4>
        <div class="policy-dialog" v-html="confidentialityAgreementInfo.Content"></div>
      </div>
      <div class="d-flex flex-column policy-dialog">
        <h4>{{ locationInfo.Title }}</h4>
        <div class="policy-dialog" v-html="locationInfo.Content"></div>
      </div>
      <div class="d-flex">
        <el-button class="cookie-button-reject mr-2" @click="handleAcceptPolicies(false)"> Reddet</el-button>
        <el-button class="cookie-button m-0" @click="handleAcceptPolicies(true)"> Kabul et ve kapat</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="'Uyarı!'" :visible.sync="productWeightWarningDialog" :modal-append-to-body="false" :before-close="handleProductWeightWarning">
      <div class="d-flex flex-column">
        <div>
          {{ productWeightWarningText }}
        </div>
        <div class="d-flex justify-content-between">
          <el-button
            class="bg-danger mt-2 mb-2 w-50"
            @click="
              () => {
                this.isWeightControlActive = false;
                this.handleProductWeightWarning();
                this.addAdvert();
              }
            "
            v-if="activateNoButton"
          >
            Hayır</el-button
          >
          <el-button class="bg-success mt-2 mb-2 w-50" @click="handleChangedAdvert" v-if="activateYesButton"> Evet</el-button>
          <el-button
            class="bg-success mt-2 mb-2 w-100"
            @click="
              () => {
                this.isWeightControlActive = false;
                this.handleProductWeightWarning();
                this.addAdvert();
              }
            "
            v-if="activateUnderstandButton"
          >
            Anladım</el-button
          >
        </div>
      </div>
    </el-dialog>
    <el-dialog v-if="!$store.state.User.token" :title="!$store.state.User.registerHomePageDialog ? 'Giriş Yap' : 'Kayıt Ol'" :visible.sync="$store.state.User.toggleLoginDialog" :modal-append-to-body="false" :close-on-click-modal="false" :before-close="closeLoginDialog">
      <Login :fromDialog="true" v-if="!$store.state.User.registerHomePageDialog" />

      <Register :fromDialog="true" v-else />
    </el-dialog>
  </div>
</template>
<script>
import Sidebar from "../components/Sidebar";
import { mapGetters } from "vuex";
import POLICIES from "../constants/policies";
import Login from "../components/login/Login.vue";
import Register from "../pages/register/Register.vue";

export default {
  components: {
    Sidebar,
    Login,
    Register,
  },
  name: "Layout",
  data() {
    return {
      isWeightControlActive: true,
      activateNoButton: false,
      activateYesButton: false,
      activateUnderstandButton: false,
      policiesDialog: false,
      paymentGocDialog: false,
      paymentGocSoonDialog: false,
      dateIsNow: null,
      pageName: "Nakliye Kullan",
      dorseDialog: false,
      depotSelectDialog: false,
      locationSelectDialog: false,
      successDialog: false,
      contactNumberInitial: '+90 (',
      adsNew: false,
      adsDate: "",
      adForm: {
        VehicleType: null,
        TrailerIds: [],
        VehicleCount: 1,
        // PaymentType: null,
        ProductType: null,
        ProductWeight: null,
        WarehouseId: null,
        DeliveryCompanyId: null,
        WarehouseCityId: null,
        WarehouseDistrictId: null,
        DeliveryCompanyCityId: null,
        DeliveryCompanyDistrictId: null,
        ContactNumber: null,
        ContactName: null,
        AdvertDate: null,
        Details: null,
      },
      reportForm: {
        Error: null,
      },
      advertResponse: { ValidationErrors: [] },
      reportErrorResponse: { ValidationErrors: [] },
      method: "post",
      advertTitle: "İlan Oluştur ve Teklif Al",
      addAdvertText: "İlan Oluştur ve Teklif Al",
      cookieDialog: false,
      showCookiePolicy: true,
      reportErrorDialog: false,
      whatIsGocDialog: false,
      productWeightWarningDialog: false,
      productWeightWarningText: "",
      warehouseDistrictIds: [],
      deliveryDistricts: [],
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        this.pageName = to.meta.name;
      },
    },
    openEditAd({ advert, method }) {
      this.method = method

      if (method === "update") {
        this.advertTitle = "İlan güncelle";
        this.addAdvertText = "İlan güncelle";
        this.adForm = {
          ID: advert.ID,
          VehicleType: advert.VehicleType,
          TrailerIds: advert.TrailerIds,
          VehicleCount: advert.VehicleCount,
          // PaymentType: advert.PaymentType,
          ProductType: advert.ProductType,
          ProductWeight: advert.ProductWeight,
          WarehouseId: advert.WarehouseId,
          DeliveryCompanyId: advert.DeliveryCompanyId,
          WarehouseCityId: advert.WarehouseCityId,
          WarehouseDistrictId: advert.WarehouseDistrictId,
          DeliveryCompanyCityId: advert.DeliveryCompanyCityId,
          DeliveryCompanyDistrictId: advert.DeliveryCompanyDistrictId,
          ContactNumber: advert.ContactNumber,
          ContactName: advert.ContactName,
          AdvertDate: advert.AdvertDate,
          Details: advert.Details,
        };
      } else {
        this.advertTitle = "İlan Oluştur ve Teklif Al";
        this.addAdvertText = "İlan Oluştur ve Teklif Al";
        this.adForm.ContactNumber = this.contactNumberInitial
      }

      // get districts
      this.getDistricts('warehouse', false)
      this.getDistricts('delivery', false)


      this.adsDialog = !this.adsDialog;
    },
  },
  beforeMount() {
    this.$store.commit("toggleLoginDialog", false);
    const acceptCookies = localStorage.getItem("acceptCookies");
    if (acceptCookies === "true") this.showCookiePolicy = false;
    else this.showCookiePolicy = true;
  },
  async created() {
    if (this.$store.state.User.token) {
      this.policiesDialog = !this.user.IsPoliciesApproved;

      await this.$store.dispatch("getCities");
      if (this.user.IsCarrier) {
        await this.$store.dispatch("getUserVehicles");
      } else {
        await this.$store.dispatch("getWarehouses");
        await this.$store.dispatch("getDeliveryCompanies");
      }
      await this.$store.dispatch("getTrailers");
    }

    this.adForm.ContactNumber = this.contactNumberInitial; // set contact number to inital value
  },
  mounted() {
    const user = JSON.parse(JSON.stringify(this.user));
    const query = this.$route.query;

    if (!user.token && query.misafirRol === "tedarikci") {
      this.handleOpenEditAd();
    } else {
      this.adsDialog = false;
    }
  },
  methods: {

    preventDelete (index, event) {
      if (event.target.selectionStart < index && (event.keyCode === 8 || event.keyCode === 46)) {
        event.preventDefault();
      }
    },
    preventCursorMoveLeft(index, event) {
      if (event.keyCode === 37 && event.target.selectionStart < index) {
        event.preventDefault();
      }
    },
    handleKeydown (index, event) {
      this.preventDelete (index, event)
      this.preventCursorMoveLeft (index, event)
    },
    moveCursorToIndex(index, event) {
      if (event.target.selectionStart < index) {
        event.target.setSelectionRange(index, index);
      }
    },
    openLoginDialog() {
      this.$store.commit("toggleLoginDialog", true);
    },
    closeLoginDialog() {
      this.$store.commit("toggleLoginDialog", false);
    },
    signOut() {
      this.signOutDialog = false;
      this.$store.dispatch("signOutUser");
    },
    async handleAcceptPolicies(isAccepted) {
      if (isAccepted) {
        const result = await this.$store.dispatch("approvePolicies");
        if (result) this.policiesDialog = false;
      } else this.signOut();
    },
    handlePaymentGocDialog() {
      this.paymentGocDialog = false;
      // this.$(".v-modal").remove();
    },
    handlePaymentGocSoonDialog() {
      this.paymentGocSoonDialog = false;
      // this.$(".v-modal").remove();
      this.adForm.PaymentType = null;
    },
    handleWhatIsGocDialog() {
      this.whatIsGocDialog = !this.whatIsGocDialog;
      // this.$(".v-modal").remove();
    },
    reportError() {},
    updateProductWeight(e) {
      this.adForm.ProductWeight = e.replace(/[^\d]/g, "");
      // this.updateTotalPrice();
    },
    getAllTrailers() {
      return this.$store.getters["trailerDetails"];
    },
    handleAcceptPolicy() {
      this.cookieDialog = false;
      this.showCookiePolicy = false;
      localStorage.setItem("acceptCookies", true);
    },
    clearAdFormData() {
      this.advertResponse = { ValidationErrors: [] };
      this.resetAdForm();
      this.adsDialog = false;
    },
    handleOpenEditAd() {
    this.$store.commit("openEditAd", {
          advert: this.adForm,
          method: "post",
      });

      // if (this.$store.state.User.token) {
      //   this.$store.commit("openEditAd", {
      //     advert: this.adForm,
      //     method: "post",
      //   });
      // } else {
      //   this.$store.commit("toggleLoginDialog", true);
      // }

    },
    getTrailerNames() {
      return this.adForm.TrailerIds.map((trailerId) => this.getAllTrailers()?.find((x) => x.ID === trailerId)?.Title).toString();
    },
    resetTrailers() {
      this.adForm.TrailerIds = [];
    },
    selectWarehouse(item) {
      this.adForm.WarehouseId = item.ID;
      this.depotSelectDialog = false;
    },
    selectDeliveryCompany(item) {
      this.adForm.DeliveryCompanyId = item.ID;
      this.locationSelectDialog = false;
    },
    handleProductWeightWarning() {
      this.productWeightWarningDialog = !this.productWeightWarningDialog;
    },
    handleChangedAdvert() {
      this.handleProductWeightWarning();
      this.isWeightControlActive = false;
      if (this.adForm.ProductWeight < 7500 && this.adForm.ProductWeight >= 1000 && this.adForm.VehicleType == 1 && this.adForm.VehicleCount == 1) {
        this.selectVehicleType(2);
      }
    },
    async getDistricts(addressType, reset=true) {
      switch (addressType) {
        case 'warehouse':
          await this.$store.dispatch("getDistrict", this.adForm.WarehouseCityId); // get data
          this.warehouseDistrictIds = this.$store.getters.districtState; // set local
          if (reset) this.adForm.WarehouseDistrictId = ''; // reset the value
          break;

        case 'delivery':
          await this.$store.dispatch("getDistrict", this.adForm.DeliveryCompanyCityId); // get data
          this.deliveryDistricts = this.$store.getters.districtState; // set local
          if (reset) this.adForm.DeliveryCompanyDistrictId = ''; // reset the value
          break;
      }
      this.$store.commit('districtCommit', []); // clear the store data
      
    },
    async addAdvert() {
      // check auth
      if (!this.$store.state.User.token) {
        this.$store.commit("toggleLoginDialog", true)
        // if not auth set login as supplier to send backend IsSupplier
        this.$store.commit("setLoginAsSupplier", true)
      }

      // if number is same as initial value send null
      if (this.adForm.ContactNumber === this.contactNumberInitial) this.adForm.ContactNumber = null

      if (this.isWeightControlActive && this.adForm.VehicleType == 0 && this.adForm.ProductWeight / this.adForm.VehicleCount > 26000) {
        this.productWeightWarningText = "Yükünüzün ağırlığı tek araca uygun değildir. Araç sayınızı arttırarak devam etmek ister misiniz?";
        this.activateNoButton = true;
        this.activateYesButton = true;
        this.activateUnderstandButton = false;
        this.handleProductWeightWarning();
      } else if (this.isWeightControlActive && this.adForm.VehicleType == 1 && this.adForm.ProductWeight > 24000 / this.adForm.VehicleCount) {
        this.productWeightWarningText = "Yükünüzün ağırlığı tek araca uygun değildir. Tır seçimiyle veya araç sayısını arttırarak devam etmek ister misiniz?";
        this.activateNoButton = true;
        this.activateYesButton = true;
        this.activateUnderstandButton = false;
        this.handleProductWeightWarning();
      } else if (this.isWeightControlActive && this.adForm.VehicleType == 1 && this.adForm.ProductWeight < 7500 / this.adForm.VehicleCount) {
        this.productWeightWarningText = "Yükünüz için daha uygun araç seçeneği mevcuttur. Kamyonet olarak seçiminize devam etmek ister misiniz?";
        this.handleProductWeightWarning();
        this.activateNoButton = true;
        this.activateYesButton = true;
        this.activateUnderstandButton = false;
      } else if (this.isWeightControlActive && this.adForm.VehicleType == 2 && this.adForm.ProductWeight > 7500 / this.adForm.VehicleCount) {
        this.productWeightWarningText = "Yükünüzün ağırlığı tek araca uygun değildir. Tır, kamyon seçimiyle veya araç sayısını arttırarak devam etmek ister misiniz?";
        this.handleProductWeightWarning();
        this.activateNoButton = true;
        this.activateYesButton = true;
        this.activateUnderstandButton = false;
      } else if (this.isWeightControlActive && this.adForm.ProductWeight / this.adForm.VehicleCount < 1000) {
        this.productWeightWarningText = "Yükünüzün ağırlığı parsiyel taşıma kapsamına girmektedir. Parsiyel taşıma olarak seçiminize devam etmek istiyorsanız info@nakliyekullan.com adresine ulaşınız.";
        this.activateNoButton = false;
        this.activateYesButton = false;
        this.activateUnderstandButton = true;
        this.handleProductWeightWarning();
      } else {
        let result;
        let date = new Date();
        // console.log(date)

        date.setHours(date.getHours() + 3);
        if (this.dateIsNow) this.adForm.AdvertDate = date;
        if (!this.dateIsNow && this.adForm.AdvertDate!=null && this.adForm.AdvertDate instanceof Date) {
          this.adForm.AdvertDate.setTime(this.adForm.AdvertDate.getTime() + (0 * 60 * 60 * 1000));
        }
        
        if (this.method == "post") {
          result = await this.$store.dispatch("postAdvert", this.adForm);
        } else {
          result = await this.$store.dispatch("updateAdvert", this.adForm);
        }

        this.advertResponse = result;
        if (!result.HasError) {
          this.resetAdForm();
          this.adsDialog = false;
        } else {
          this.dateIsNow = null;
          // if number is null after err reset to initial
          if (this.adForm.ContactNumber === null) this.adForm.ContactNumber = this.contactNumberInitial
        }

        this.isWeightControlActive = true;
        // this.adForm.AdvertDate = null;
      }
    },
    resetAdForm() {
      console.log('resetted')
      this.adForm = {
        VehicleType: null,
        TrailerIds: [],
        VehicleCount: 1,
        // PaymentType: null,
        ProductType: null,
        ProductWeight: null,
        WarehouseId: null,
        DeliveryCompanyId: null,
        WarehouseCityId: null,
        WarehouseDistrictId: null,
        DeliveryCompanyCityId: null,
        DeliveryCompanyDistrictId: null,
        ContactNumber: this.contactNumberInitial,
        ContactName: null,
        AdvertDate: null,
        Details: null,
      };
      this.dateIsNow = null;
      console.log(this.adForm.ContactNumber)

    },
    async selectVehicleType(selectedType) {
      this.resetTrailers();
      this.adForm.VehicleType = selectedType;
    },
    selectTrailerType(TrailerId) {
      if (this.adForm.TrailerIds.some((x) => x == TrailerId)) {
        this.adForm.TrailerIds.splice(
          this.adForm.TrailerIds.findIndex((x) => x == TrailerId),
          1,
        );
      } else {
        this.adForm.TrailerIds.push(TrailerId);
      }
    },
    createAds() {
      this.adsDialog = false;
      this.successDialog = true;
    },
    turnHome() {
      this.successDialog = false;
      this.$router.push({ name: "homedashboard" });
    },
    changeAccType() {
      this.$store.commit("accTypeChange", !this.user.IsCarrier);
    },
  },
  computed: {
    token() {
      return this.$store.getters["getToken"];
    },
    locationInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.LOCATION_DATA);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    kvkkInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.KVKK);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    userAgreementInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.USER_AGREEMENT);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    confidentialityAgreementInfo: {
      get() {
        const cookie = this.$store.getters["policies"].find((policy) => policy.Type === POLICIES.CONFIDENTIALITY);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    ...mapGetters({
      user: "userState",
      warehouses: "getWareHouses",
      deliveryCompanies: "getDeliveryCompanies",
      loading: "loading",
      systemSettings: "systemSettings",
      cityState: "cityState",
      districtState: "districtState"
    }),
    cookieInfo: {
      get() {
        const cookie = this.$store.getters["policies"]?.find((policy) => policy.Type === POLICIES.COOKIE);
        return cookie ? cookie : { Title: "", Content: "" };
      },
    },
    adsDialog: {
      get() {
        return this.$store.getters["adsDialog"];
      },
      set(newValue) {
        this.$store.commit("adsDialog", newValue);
      },
    },
    howToUseDialog: {
      get() {
        return this.$store.getters["howToUseDialog"];
      },
      set(newValue) {
        this.$store.commit("howToUseDialog", newValue);
      },
    },
    openEditAd: {
      get() {
        return this.$store.getters["openEditAd"];
      },
      set(newValue) {
        this.$store.commit("openEditAd", newValue);
      },
    },
    warehouseTitle() {
      return this.warehouses?.find((x) => {
        return x.ID === this.adForm.WarehouseId;
      })?.Title;
    },
    deliveryCompanyTitle() {
      return this.deliveryCompanies?.find((x) => {
        return x.ID === this.adForm.DeliveryCompanyId;
      })?.Title;
    },
    naku() {
      return this.$store.getters["userState"].Naku;
    },
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
